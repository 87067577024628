"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.loadMatomoScript = exports.waitForConsent = exports.initMatomoTracking = void 0;
var url = "https://analytics-pv.oebb.at/matomo/";
function getPaq() {
    return window._paq = window._paq || [];
}
function initMatomoTracking(siteId) {
    var _paq = getPaq();
    _paq.push(['trackPageView']);
    _paq.push(['enableLinkTracking']);
    _paq.push(['trackVisibleContentImpressions']);
    _paq.push(['setTrackerUrl', url + 'matomo.php']);
    _paq.push(['setSiteId', siteId]);
    waitForConsent(function () { return loadMatomoScript(url + 'matomo.js'); });
    return _paq;
}
exports.initMatomoTracking = initMatomoTracking;
function waitForConsent(callback) {
    var _a, _b;
    if (!document.currentScript.dataset.consentManager) {
        // Consent manager not used: Load matomo script immediately
        callback();
    }
    else if ((_b = (_a = window.consentManager) === null || _a === void 0 ? void 0 : _a.accepted) === null || _b === void 0 ? void 0 : _b.includes('analytics')) {
        // Consent already given: Load matomo script immediately
        callback();
    }
    else if (window.consentManager) {
        // Consent manager loaded, but analytics not accepted
        removeConsent();
    }
    else {
        // Wait until consent is given
        var listener = function (event) {
            var _a;
            if ((_a = event.detail.accepted) === null || _a === void 0 ? void 0 : _a.includes('analytics')) {
                callback();
            }
            else {
                removeConsent();
            }
        };
        window.addEventListener('consent-updated', listener);
    }
}
exports.waitForConsent = waitForConsent;
function loadMatomoScript(url) {
    getPaq().push(['setConsentGiven']);
    var scripts = document.getElementsByTagName('script');
    for (var i = 0; i < scripts.length; i++) {
        if (scripts[i].src === url) {
            // Script already loaded, nothing else to do
            return;
        }
    }
    var matomoScript = document.createElement('script');
    matomoScript.type = 'text/javascript';
    matomoScript.async = true;
    matomoScript.defer = true;
    matomoScript.src = url;
    scripts[0].parentNode.insertBefore(matomoScript, scripts[0]);
}
exports.loadMatomoScript = loadMatomoScript;
function removeConsent() {
    // Stop tracking, if already active
    getPaq().push(['requireConsent']);
    // Delete the cookies
    var expires = new Date(0).toUTCString();
    document.cookie
        .split(/; */)
        .filter(function (cookie) { return cookie.startsWith('_pk_'); })
        .map(function (cookie) { return cookie.split('=')[0]; })
        .forEach(function (name) { return document.cookie = "".concat(name, "=;expires=").concat(expires, ";path=/"); });
}
